// template_viewer.jsx

import React, { forwardRef, useImperativeHandle } from 'react';

import * as fabric from 'fabric';

/**
 * Компонент для предварительного просмотра графики с использованием fabric.js
 * @param {object} props - Свойства компонента
 * @param {object} props.graphicData - Данные графики
 * @param {number} props.aspectRatio - Соотношение сторон канваса
 */
const TemplateViewer = forwardRef(({ graphicData, aspectRatio }, ref) => {
  const canvasRef = React.useRef(null); // Ссылка на элемент canvas
  const fabricRef = React.useRef(null); // Ссылка на экземпляр fabric.Canvas
  const containerRef = React.useRef(null); // Ссылка на контейнер канваса

  // Инициализация fabric.Canvas при загрузке graphicData
  React.useEffect(() => {
    if (!graphicData) {
      console.log("No graphicData provided");
      return;
    }

    const { frame } = graphicData;
    if (!frame || typeof frame.width !== 'number' || typeof frame.height !== 'number') {
      console.log("Invalid frame data in graphicData");
      return;
    }

    const canvasElement = canvasRef.current;
    if (!canvasElement) {
      console.log("Canvas не найден");
      return;
    }

    if (!fabricRef.current) {
      const fabricCanvas = new fabric.Canvas(canvasElement, {
        width: frame.width,
        height: frame.height,
        selection: true,
        perPixelTargetFind: true,
        targetFindTolerance: 5,
      });

      fabricCanvas.preserveStacking = true;
      fabricRef.current = fabricCanvas;

      return () => {
        fabricCanvas.dispose(); // Очистка при размонтировании компонента
      };
    }
  }, [graphicData]);

  // Основной эффект для загрузки шрифтов и рендеринга сцены
  React.useEffect(() => {
    if (!graphicData) return;
    const fabricCanvas = fabricRef.current;
    if (!fabricCanvas) return;

    fabricCanvas.clear(); // Очистка холста перед рендерингом

    const { scenes } = graphicData;
    const scene = scenes[0];
    if (!scene) {
      console.log("No scene found in graphicData");
      return;
    }

    // Функция для загрузки шрифта по URL и добавления его в документ
    const loadFont = (fontURL, fontFamily) => {
      return new Promise((resolve, reject) => {
        const font = new FontFace(fontFamily, `url(${fontURL})`, {
          style: 'normal',
          weight: 'normal',
        });
        font.load()
          .then((loadedFont) => {
            document.fonts.add(loadedFont);
            document.fonts.ready.then(() => {
              resolve();
            });
          })
          .catch((error) => {
            console.error(`Ошибка загрузки шрифта ${fontFamily} с URL ${fontURL}:`, error);
            reject(error);
          });
      });
    };

    // Функция для применения преобразований родительского слоя к дочернему слою
    const applyParentTransform = (layer, parentTransform) => {
      const scaledLeft = layer.left * parentTransform.scaleX;
      const scaledTop = layer.top * parentTransform.scaleY;
      const angleRad = fabric.util.degreesToRadians(parentTransform.angle);
      const rotatedLeft = scaledLeft * Math.cos(angleRad) - scaledTop * Math.sin(angleRad);
      const rotatedTop = scaledLeft * Math.sin(angleRad) + scaledTop * Math.cos(angleRad);
      const absoluteLeft = parentTransform.left + rotatedLeft;
      const absoluteTop = parentTransform.top + rotatedTop;
      const absoluteAngle = parentTransform.angle + layer.angle;
      const absoluteScaleX = parentTransform.scaleX * layer.scaleX;
      const absoluteScaleY = parentTransform.scaleY * layer.scaleY;

      return {
        left: absoluteLeft,
        top: absoluteTop,
        angle: absoluteAngle,
        scaleX: absoluteScaleX,
        scaleY: absoluteScaleY,
      };
    };

    // Функция для загрузки всех шрифтов, используемых в слоях
    const loadAllFonts = async (layers) => {
      const fontPromises = [];

      const collectFonts = (layer) => {
        if (layer.type === 'StaticText' && layer.fontURL && layer.fontFamily) {
          fontPromises.push(loadFont(layer.fontURL, layer.fontFamily));
        }
      };

      layers.forEach(collectFonts);
      await Promise.all(fontPromises); // Ожидание загрузки всех шрифтов
    };

    // Функция для создания объектов fabric.js из слоев
    const createFabricObject = async (layer, parentTransform = { left: 0, top: 0, angle: 0, scaleX: 1, scaleY: 1 }) => {
      const absoluteTransform = applyParentTransform(layer, parentTransform);

      switch (layer.type) {
        case 'Background':
          return new fabric.Rect({
            left: absoluteTransform.left,
            top: absoluteTransform.top,
            width: layer.width * absoluteTransform.scaleX,
            height: layer.height * absoluteTransform.scaleY,
            fill: layer.fill || '#FFFFFF',
            opacity: layer.opacity,
            selectable: false,
            evented: false,
            originX: layer.originX || 'left',
            originY: layer.originY || 'top',
          });
        case 'StaticRectangle':
          return new fabric.Rect({
            left: absoluteTransform.left,
            top: absoluteTransform.top,
            width: layer.width * absoluteTransform.scaleX,
            height: layer.height * absoluteTransform.scaleY,
            fill: layer.fill || null,
            stroke: layer.stroke || '#000000',
            strokeWidth: layer.strokeWidth || 1,
            opacity: layer.opacity,
            selectable: false,
            evented: false,
            originX: layer.originX || 'left',
            originY: layer.originY || 'top',
            scaleX: absoluteTransform.scaleX,
            scaleY: absoluteTransform.scaleY,
            angle: absoluteTransform.angle,
            flipX: layer.flipX,
            flipY: layer.flipY,
            skewX: layer.skewX,
            skewY: layer.skewY,
            shadow: layer.shadow ? new fabric.Shadow(layer.shadow) : null,
          });
        case 'StaticPath':
          return new fabric.Path(layer.path.map(cmd => cmd.join(' ')).join(' '), {
            left: absoluteTransform.left,
            top: absoluteTransform.top,
            fill: layer.fill || '#000000',
            stroke: layer.stroke || null,
            strokeWidth: layer.strokeWidth || 1,
            opacity: layer.opacity,
            selectable: false,
            evented: false,
            scaleX: absoluteTransform.scaleX,
            scaleY: absoluteTransform.scaleY,
            angle: absoluteTransform.angle,
            flipX: layer.flipX,
            flipY: layer.flipY,
            skewX: layer.skewX,
            skewY: layer.skewY,
            originX: layer.originX || 'left',
            originY: layer.originY || 'top',
            shadow: layer.shadow ? new fabric.Shadow(layer.shadow) : null,
          });
        case 'StaticText':
          {
            const fabricTextbox = new fabric.Textbox(layer.text, {
              left: absoluteTransform.left,
              top: absoluteTransform.top,
              fontSize: layer.fontSize,
              fontFamily: layer.fontFamily || 'sans-serif',
              fontStyle: layer.fontStyle || 'normal',
              fontWeight: layer.fontWeight || 'normal',
              fill: layer.fill || '#000000',
              textAlign: layer.textAlign || 'center',
              originX: layer.originX || 'left',
              originY: layer.originY || 'top',
              scaleX: absoluteTransform.scaleX,
              scaleY: absoluteTransform.scaleY,
              angle: absoluteTransform.angle,
              flipX: layer.flipX,
              flipY: layer.flipY,
              skewX: layer.skewX,
              skewY: layer.skewY,
              opacity: layer.opacity,
              shadow: layer.shadow ? new fabric.Shadow(layer.shadow) : null,
              selectable: true,
              hasControls: false,  // Hide controls
              hasBorders: true,    // Hide borders
              editable: true,
              lockMovementX: true, // Prevent movement
              lockMovementY: true, // Prevent movement
              hoverCursor: 'text',
              width: layer.width ? layer.width * absoluteTransform.scaleX : 200,
              lineHeight: layer.lineHeight || 1.16,
              charSpacing: layer.charSpacing || 0,
            });

            return fabricTextbox;
          }
        case 'StaticImage':
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            
            img.onload = () => {
              const fabricImage = new fabric.Image(img, {
                left: absoluteTransform.left,
                top: absoluteTransform.top,
                scaleX: absoluteTransform.scaleX,
                scaleY: absoluteTransform.scaleY,
                angle: absoluteTransform.angle,
                flipX: layer.flipX,
                flipY: layer.flipY,
                skewX: layer.skewX,
                skewY: layer.skewY,
                opacity: layer.opacity,
                originX: layer.originX || 'left',
                originY: layer.originY || 'top',
                selectable: false,
                evented: false,
                shadow: layer.shadow ? new fabric.Shadow(layer.shadow) : null,
              });
              
              resolve(fabricImage);
            };
            img.onerror = () => {
              console.error(`Ошибка загрузки изображения с URL ${layer.src}`);
              reject(new Error('Image loading error'));
            };
            img.src = layer.src;
          });

        case 'Group':
          // Ignore groups
          return null;

        default:
          console.warn(`Unsupported layer type: ${layer.type}`);
          return null;
      }
    };

    /**
     * Функция для рендеринга сцены на канвасе
     */
    const renderScene = async () => {
      try {
        await loadAllFonts(scene.layers);
        
        // Последовательное добавление объектов
        for (const layer of scene.layers) {
          const fabricObject = await createFabricObject(layer);
          if (fabricObject) {
            fabricCanvas.add(fabricObject);
            fabricCanvas.renderAll();
          }
        }
      } catch (error) {
        console.error('Ошибка при загрузке шрифтов или отрисовке сцены:', error);
      }
    };

    renderScene();

    /**
     * Обработчик события для редактирования текста
     */
    const handleTextEditing = (e) => {
      const target = e.target;
      if (target && target.type === 'textbox') {
        // Устанавливаем текстовый объект активным и сразу включаем редактирование
        fabricCanvas.setActiveObject(target);
        target.enterEditing();
        target.selectAll();
        fabricCanvas.renderAll(); // Обновляем холст
      }
    };

    // Prevent movement of all objects
    const preventMovement = (e) => {
      const target = e.target;
      if (target && (target.type === 'textbox' || target.type === 'image' || target.type === 'rect')) {
        e.target.set({
          left: e.target.originalState.left,
          top: e.target.originalState.top,
        });
        fabricCanvas.renderAll();
      }
    };

    fabricCanvas.on('object:selected', handleTextEditing);
    fabricCanvas.on('object:moving', preventMovement);

    /**
     * Функция для ресайза канваса при изменении размера окна
     */
    const resizeCanvas = () => {
      const container = containerRef.current;
      const canvasElement = canvasRef.current;
      const fabricCanvas = fabricRef.current;
      if (container && canvasElement && fabricCanvas) {
        const containerWidth = container.clientWidth;
        const newWidth = containerWidth;
        const newHeight = newWidth / aspectRatio;

        // Устанавливаем CSS размеры
        canvasElement.style.width = `${newWidth}px`;
        canvasElement.style.height = `${newHeight}px`;

        // Устанавливаем фактические размеры канваса
        fabricCanvas.setWidth(newWidth);
        fabricCanvas.setHeight(newHeight);

        // Масштабируем канвас
        const scaleX = newWidth / graphicData.frame.width;
        const scaleY = newHeight / graphicData.frame.height;
        const scale = Math.min(scaleX, scaleY);

        fabricCanvas.setZoom(scale);
      }
    };

    // Добавляем обработчик ресайза
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas(); // Вызовем сразу для инициализации

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      fabricCanvas.off('object:selected', handleTextEditing); // Убираем обработчик
      fabricCanvas.off('object:moving', preventMovement);
    };
  }, [graphicData, aspectRatio]);

  /**
   * Функция для скачивания PNG
   */
  const downloadPNG = () => {
    const fabricCanvas = fabricRef.current;
    if (!fabricCanvas) {
      console.error('Canvas не инициализирован');
      return;
    }

    const dataURL = fabricCanvas.toDataURL({
      format: 'png',
      quality: 1,
    });

    // Создаем временный элемент ссылки для скачивания
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'template.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Экспортируем функцию downloadPNG через ref
  useImperativeHandle(ref, () => ({
    downloadPNG,
  }));

  return (
    <div ref={containerRef} style={styles.previewContainer}>
      <canvas
        ref={canvasRef}
        style={styles.canvas}
      />
    </div>
  );
});

/**
 * Объект с стилями для компонента TemplateViewer
 */
const styles = {
  previewContainer: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  },
  canvas: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
};

export default TemplateViewer;
