// TemplateDetailComponent.jsx

import React, { useRef } from 'react';
import * as fabric from 'fabric'; // Не изменяйте этот импорт

// Импортируйте данные графики из отдельного файла
import graphicData from './TstgraphicData.jsx'; 

// Импортируем TemplateViewer из отдельного файла
import TemplateViewer from './Template_viewer.jsx';

/**
 * Основной компонент, отображающий детали шаблона
 */
const TemplateDetailComponent = () => {
  const viewerRef = useRef(null);

  /**
   * Функция для скачивания PNG, вызывающая метод TemplateViewer
   */
  const handleDownload = () => {
    if (viewerRef.current) {
      viewerRef.current.downloadPNG();
    }
  };

  return (
    <div style={styles.mainContainer}>
      <GraphicDetailLayout graphicData={graphicData} onDownload={handleDownload} viewerRef={viewerRef} />
    </div>
  );
};

export default TemplateDetailComponent;

/**
 * Компонент для соответствия желаемому макету
 * @param {object} props - Свойства компонента
 * @param {object} props.graphicData - Данные графики
 * @param {function} props.onDownload - Функция для скачивания PNG
 * @param {object} props.viewerRef - Реф для TemplateViewer
 */
const GraphicDetailLayout = ({ graphicData, onDownload, viewerRef }) => {
  // Извлекаем ширину и высоту для расчета соотношения сторон
  const width = graphicData.frame?.width || 800;
  const height = graphicData.frame?.height || 600;
  const aspectRatio = width && height ? width / height : 16 / 9; // По умолчанию 16:9

  // Формируем ключевые слова
  const keywords = Array.isArray(graphicData.keywords)
    ? graphicData.keywords.join(', ')
    : 'No keywords';

  // Определяем, является ли шаблон бесплатным или платным
  const freeOrPaid = graphicData.is_free ? 'This template is free' : 'This template is premium';

  return (
    <div style={styles.container}>
      {/* Левый столбец для канваса */}
      <div style={styles.leftColumn}>
        <TemplateViewer graphicData={graphicData} aspectRatio={aspectRatio} ref={viewerRef} />
      </div>

      {/* Правый столбец для дополнительной информации */}
      <div style={styles.rightColumn}>
        <div style={styles.infoContainer}>
          <h2>{graphicData.name || 'Template Title'}</h2>
          <p style={styles.keywords}>
            <strong>Keywords:</strong> {keywords}
          </p>
          <p>{freeOrPaid}</p>
          {!graphicData.is_free && (
            <button 
              onClick={onDownload} // Обработчик события для скачивания
              className="download-button"
            >
              Download PNG
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Объект с стилями для компонентов
 */
const styles = {
  mainContainer: {
    marginTop: '20px',
    
    boxSizing: 'border-box',
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '20px',
  },
  leftColumn: {
    flex: '1 1 300px',
    minWidth: '300px',
    maxWidth: '720px',
    boxSizing: 'border-box',
    width: '100%',
  },
  rightColumn: {
    flex: '1 1 300px',
    minWidth: '300px',
    maxWidth: '780px',
    boxSizing: 'border-box',
    width: '100%',
  },
  infoContainer: {
    padding: '10px',
    boxSizing: 'border-box',
  },
  keywords: {
    fontSize: '0.9em',
    color: '#555',
  },
};
