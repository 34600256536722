import React, { useState, useEffect } from 'react';
import '../styles/CookieBanner.css';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem('cookieConsent', 'accept_all');
    setShowBanner(false);
  };

  const handleRejectAll = () => {
    localStorage.setItem('cookieConsent', 'reject_all');
    setShowBanner(false);
  };

  const handleCustomize = () => {
    // console.log('Open customization options');
  };

  const handleClose = () => {
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className='cookie-banner'>
      <button onClick={handleClose} className='close-button'>X</button>
      <h2 className='title'>We value your privacy</h2>
      <p className='description'>
        We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic.
        By clicking "Accept All", you consent to our use of cookies.
      </p>
      <div className='buttons'>
        <button onClick={handleCustomize} className='customize-button'>Customize</button>
        <button onClick={handleRejectAll} className='reject-button'>Reject All</button>
        <button onClick={handleAcceptAll} className='accept-button'>Accept All</button>
      </div>
    </div>
  );
};

export default CookieBanner;
